export const HOME_BANNER_LOGO = {
  dots: false,
  infinite: true,
  speed: 16000,
  autoplay: true,
  autoplaySpeed: 0,
  variableWidth: true,
  slidesToShow: 4,
  slidesToScroll: 4,
  arrows: false,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: false,
      },
    },
  ],
};

export const HOME_ENDOW = {
  dots: true,
  infinite: true,
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 5000,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  responsive: [
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
      },
    },
  ],
};

export const HOME_NEWS = {
  dots: false,
  infinite: true,
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 5000,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  responsive: [
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        infinite: true,
      },
    },
  ],
};

export const FormatNumber = (num?: number): string =>
  num !== undefined
    ? num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    : '';
