import styled from 'styled-components';

export const HomeWrapper = styled.div`
  @media (max-width: 769px) {
    padding-top: 56px;
  }
  @keyframes cloud1 {
    0% {
      left: 39%;
    }
    50% {
      left: -10%;
    }
    100% {
      left: 39%;
    }
  }
  @keyframes cloud2 {
    0% {
      left: 70%;
    }
    50% {
      left: 80%;
    }
    100% {
      left: 70%;
    }
  }
  @keyframes cloud3 {
    0% {
      right: 0;
    }
    50% {
      right: 20%;
    }
    100% {
      right: 0;
    }
  }
  @keyframes float {
    0%,
    100% {
      transform: translate(-50%, -50%);
    }
    50% {
      transform: translate(-50%, calc(-50% + 10px));
    }
  }
  .homeBanner {
    background: linear-gradient(
        246.57deg,
        rgba(249, 178, 23, 0.2) 0%,
        rgba(29, 117, 112, 0.2) 40.45%,
        rgba(122, 48, 22, 0.2) 80.1%
      ),
      rgba(253, 185, 19, 0.25);
    padding: 160px 0 60px;
    position: relative;
    .segTitle {
      position: relative;
      img {
        position: absolute;
        top: 40px;
        left: 265px;
        width: 75px;
      }
      span {
        &:nth-child(1) {
          color: var(--color-2);
        }
        &:nth-child(2) {
          color: var(--color-1);
        }
        &:nth-child(3) {
          color: var(--color-3);
        }
      }
    }
    .--search {
      margin-top: 24px;
      width: 100%;
      .--input {
        position: relative;
      }
      i {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 12px;
      }
      input {
        height: 80px;
        border: 1px solid #c6c6c6;
        width: 100%;
        border-radius: 16px;
        padding-left: 40px;
        &:focus,
        &:focus-visible {
          outline: none;
        }
      }
      .--note {
        margin-top: 12px;
      }
    }
    article {
      padding-left: 20px;
      font-size: 16px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        height: 90%;
        width: 5px;
        background: linear-gradient(270deg, #f16f24 4.72%, #ef562d 97.4%);
        border-radius: 24px;
        transform: translate(0, -50%);
      }
    }
    .container-fluid {
      display: grid;
      grid-template-columns: 500px auto;
      gap: 120px;
      position: relative;
      z-index: 1;
    }
    .--left {
      display: flex;
      flex-flow: column;
    }
    article {
      margin: auto 0 0 0;
    }
    .--right {
      .--map {
        position: relative;
        .--img {
          img {
            width: 100%;
          }
        }
        span {
          position: absolute;
          left: 87%;
          bottom: 14%;
          transform: translate(-50%, -50%);
          width: max-content;
        }
      }
      .--event {
        position: absolute;
        top: 0;
        right: -60px;
        width: 310px;
        .--titleEvent {
          font-weight: 700;
        }
        .--item {
          display: flex;
          align-items: center;
          background: rgba(255, 255, 255, 0.3);
          box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.09);
          backdrop-filter: blur(22px);
          border-radius: 8px;
          overflow: hidden;
          color: #000;
          border: 2px solid transparent;
          transition: var(--transition-all);
          &:hover {
            border: 2px solid var(--color-2);
            .--name {
              color: var(--color-2);
            }
          }
        }
        .--txt {
          display: flex;
          flex-flow: column;
          gap: 8px;
          padding: 12px;
          font-size: 12px;
        }
        .--name {
          transition: var(--transition-all);
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 20px;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
        .--info {
          display: flex;
          gap: 6px;
        }
        .--cate {
          background: linear-gradient(270deg, #f16f24 4.72%, #ef562d 97.4%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .--listEvent {
          display: flex;
          flex-flow: column;
          gap: 8px;
          margin-top: 16px;
        }
        .--img {
          width: 100px;
          height: 100px;
          flex: 0 0 auto;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .--marker {
        .--item {
          position: absolute;
          top: 0;
          left: 0;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          animation: pulse-animation 2s infinite;
          &:hover {
            img {
              animation: zoomOut 2s ease infinite;
            }
            .--name {
              opacity: 1;
              pointer-events: auto;
              bottom: calc(100% + 10px);
            }
          }
          .--name {
            position: absolute;
            background: #fff;
            pointer-events: none;
            text-align: center;
            position: absolute;
            background: rgba(255, 255, 255, 0.7);
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(5px);
            border-radius: 6px;
            width: max-content;
            color: #000;
            font-weight: 600;
            padding: 8px;
            left: 50%;
            bottom: 100%;
            transform: translate(-50%, 0);
            opacity: 0;
            transition: var(--transition-all);
          }
        }
      }
    }
    .--cloud {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      img {
        width: 100%;
      }
    }
    .--listLogo {
      position: relative;
      z-index: 1;
      margin-top: 60px;
      &.litle {
        display: flex;
        justify-content: center;
      }
      .slick-slide {
        width: 120px;
        flex: 0 0 auto;
        margin: 0 50px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .slick-track {
        display: flex;
      }
      .--logo {
        height: 80px;
        align-items: center;
        justify-items: center;
        img {
          max-height: 100%;
          object-fit: contain;
          height: 100%;
        }
      }
    }
    @media (max-width: 1439px) {
      .segTitle {
        img {
          top: 32px;
          left: 225px;
          width: 70px;
        }
      }
    }
    @media (max-width: 1279px) {
      .segTitle {
        img {
          top: 35px;
          left: 202px;
          width: 55px;
        }
      }
    }
    @media (max-width: 769px) {
      padding: 60px 0;
      .container-fluid {
        grid-template-columns: repeat(1, 1fr);
        gap: 40px;
      }
      .segTitle {
        img {
          top: 32px;
          left: 202px;
          width: 60px;
        }
      }
      .--right {
        .--map {
          span {
            right: 0;
            left: unset;
            transform: none;
            bottom: 20px;
          }
        }
      }
      .--listLogo {
        .slick-slide {
          margin: 0 8px;
        }
      }
    }
  }
  .homeDesign {
    background: linear-gradient(
      180deg,
      rgba(29, 117, 112, 0.1) 0%,
      rgba(38, 142, 136, 0) 100%
    );
    padding-top: 40px;
    .--background {
      position: relative;
      .--img {
        img {
          width: 100%;
        }
      }
      .--cloud {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        img {
          position: absolute;

          &.--cloud1 {
            top: 70%;
            animation:
              cloud1 30s linear infinite,
              float 2s ease-in-out infinite;
            width: 30%;
          }
          &.--cloud2 {
            top: 26%;
            width: 18%;
            animation:
              cloud2 15s linear infinite,
              float 2s ease-in-out infinite;
          }
        }
      }
      .--house {
        position: absolute;
        z-index: 1;
        right: 0;
        bottom: 6%;
        width: 48%;
        overflow: hidden;
        .--img1 {
          width: 100%;
          position: relative;
        }
        .--img2 {
          position: absolute;
          right: 0;
          height: 64%;
          top: 0;
          width: 19%;
          z-index: 2;
          object-fit: cover;
        }
        .--img3 {
          position: absolute;
          top: 36%;
          right: 0;
          width: 26%;
          animation:
            cloud2 10s linear infinite,
            float 2s ease-in-out infinite;
        }
      }
      .--house1 {
        position: absolute;
        top: -13%;
        right: 8%;
        z-index: 1;
        width: 18.5%;
      }
    }
    .container-fluid {
      position: relative;
    }
    .--txt {
      position: absolute;
      top: 160px;
      left: 80px;
      color: #fff;
      z-index: 9;
      img {
        position: absolute;
        left: 250px;
        top: 40px;
      }
    }
    .subTitle {
      color: var(--color-2);
    }
    article {
      margin-top: 16px;
    }
    @media (max-width: 1439px) {
      .--txt {
        img {
          left: 220px;
          top: 44px;
          width: 50px;
        }
      }
      .--background {
        .--cloud {
          img {
            &.--cloud1 {
              width: 26%;
            }
          }
        }
      }
    }
    @media (max-width: 1279px) {
      .--txt {
        img {
          left: 200px;
          top: 40px;
          width: 46px;
        }
      }
    }
    @media (max-width: 769px) {
      padding: 60px 0;
      .--txt {
        img {
          left: 190px;
          top: 32px;
          width: 50px;
        }
      }
      .container-fluid {
        display: flex;
        gap: 24px;
        flex-flow: column-reverse;
      }
      .--background {
        margin-top: 0;
      }
      .--txt {
        position: relative;
        top: unset;
        left: unset;
        width: 100%;
        color: #000;
      }
    }
  }
  .homeEndow {
    padding: 60px 0;
    .titleFrame {
      text-align: center;
    }
    .--listEndow {
      margin-top: 24px;
      &.litle {
        display: flex;
        gap: 24px;
        justify-content: center;
        .--item {
          width: calc(100% / 3 - 16px);
        }
      }
    }
    .slick-list {
      margin: 0 -12px;
    }
    .slick-track {
      height: max-content;
      display: flex;
    }
    .slick-slide {
      margin: 0 12px;
    }
    img {
      width: 100%;
    }
  }
  .homeNews {
    background: #f5fcff;
    padding: 80px 0;
    .--title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .slider {
      margin-top: 24px;
      position: relative;
    }
    .slick-list {
      margin: 0 -22px;
    }
    .--listNews {
      overflow: hidden;
      &.little {
        display: flex;
        grid-gap: 42px;
        .--item {
          width: calc(100% / 3 - 28px);
        }
      }
    }
    .--viewDetail {
      display: flex;
      align-items: center;
      gap: 4px;
      color: var(--color-1);
    }
    .slick-slide {
      margin: 0 22px;
    }
    .slick-track {
      display: flex;
    }
    .--arrow {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 1px dashed #c6c6c6;
      cursor: pointer;
      &.next {
        left: calc(100% + 12px);
      }
      &.prev {
        right: calc(100% + 12px);
      }
    }
    .--item {
      color: #000;
      &:hover {
        .--name {
          color: var(--color-4);
        }
      }
      .--txt {
        margin-top: 24px;
      }

      .--img {
        width: 100%;
        aspect-ratio: 4/3;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .--cate {
        color: var(--color-1);
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        line-height: normal;
      }
      .--name {
        font-size: 22px;
        margin-top: 8px;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 30px;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        transition: var(--transition-all);
      }
      .--time {
        color: #858585;
        display: flex;
        gap: 8px;
        margin-top: 16px;
        align-items: center;
      }
      article {
        margin-top: 16px;
        line-height: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 22px;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        transition: var(--transition-all);
      }
    }
    @media (max-width: 769px) {
      padding: 60px 0;
      overflow: hidden;
      .slick-list {
        margin: 0;
      }
      .--listNews {
        width: 100vw;
      }
      .slick-track {
        display: block !important;
      }
      .--listNews {
        &.little {
          width: 100%;
          .--item {
            width: 100%;
          }
        }
      }
      .--viewDetail {
        margin: 24px auto 0;
        width: max-content;
      }
      .slick-slide {
        margin: 0 12px 0 0;
        width: 300px;
      }
      .--arrow {
        background: #fff;
        color: var(--color-1);
        &.next {
          left: unset;
          right: -8px;
        }
        &.prev {
          right: unset;
          left: -8px;
        }
      }
    }
  }
  .homePrize {
    padding: 80px 0;
    overflow: hidden;
    .titleFrame {
      text-align: center;
    }

    article {
      text-align: center;
      margin: 0 auto;
      width: 420px;
      margin-top: 16px;
    }

    .slick-list {
      margin: 0 -12px;
    }
    .slick-track {
      display: flex !important;
    }
    .slick-slide {
      transform-origin: center bottom;
      margin: 0 12px;
      &.slick-active {
        z-index: 5;
        opacity: 1;
      }
    }

    .slick-center {
      transform: rotateY(0deg);
      z-index: 10;
      opacity: 1;
    }

    .--listPrize {
      margin-top: 32px;
      position: relative;
      perspective: 1000px;
      &.litle {
        display: flex;
        justify-content: center;
        gap: 20px;
        .--item {
          width: calc(20% - 16px);
        }
      }
    }

    .--item {
      text-align: center;
    }

    .--img {
      width: 100%;
      aspect-ratio: 5 / 4;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f5fcff;
      img {
        max-width: 90%;
        max-height: 90%;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .--time {
      margin-top: 20px;
      font-size: 12px;
      color: var(--color-1);
      padding: 0 12px;
    }

    .--name {
      margin-top: 12px;
      font-weight: 600;
      font-size: 18px;
      line-height: normal;
      padding: 0 12px;
    }
    @media (max-width: 769px) {
      padding: 60px 0;
      article {
        width: 100%;
      }
      .--listPrize {
        &.litle {
          .--item {
            width: 100%;
          }
        }
      }
      .slick-track {
        display: block;
      }
    }
  }
`;
